import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "author": "Jonah Wong",
  "date": "2006-03-05 UTC -8",
  "length": null,
  "location": "Corvallis, OR",
  "path": "/the-common-dog/",
  "tags": ["dogs", "personal"],
  "title": "The Common Dog"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The domestic dog (Canis lupus familiaris when considered a subspecies of the wolf or Canis familiaris when considered a distinct species) is a member of the genus Canis (canines), which forms part of the wolf-like canids, and is the most widely abundant terrestrial carnivore.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABUBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAEVZoNRYU//xAAaEAADAQADAAAAAAAAAAAAAAABAgMRBBIT/9oACAEBAAEFAmpjC52XeiiaM3gipxgFl//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/AYf/xAAdEAACAgIDAQAAAAAAAAAAAAAAAQIRITESImGx/9oACAEBAAY/Aq2x4tfDkok01pkkrz6dT//EABwQAQACAgMBAAAAAAAAAAAAAAEAIRExQVFhof/aAAgBAQABPyGzBpwfZlWjQcvZTnD7HYaC41si+0HgwKu5/9oADAMBAAIAAwAAABBLH//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAwEBPxBZUcv/xAAWEQEBAQAAAAAAAAAAAAAAAAABACH/2gAIAQIBAT8QIA7f/8QAHhABAAIBBAMAAAAAAAAAAAAAAQARITFRYYFxoeH/2gAIAQEAAT8QQiMEmFN0YNNmCrNF17iPeSFXj25cjEEuDQV+J1xFWoBlm/k//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Dogs",
          "title": "Dogs",
          "src": "/static/75a781cfb51c556872744d05712773bc/8e1fc/dogs.jpg",
          "srcSet": ["/static/75a781cfb51c556872744d05712773bc/863e1/dogs.jpg 225w", "/static/75a781cfb51c556872744d05712773bc/20e5d/dogs.jpg 450w", "/static/75a781cfb51c556872744d05712773bc/8e1fc/dogs.jpg 900w", "/static/75a781cfb51c556872744d05712773bc/e5166/dogs.jpg 1200w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`The dog and the extant gray wolf are sister taxa as modern wolves are not closely related to the wolves that were first domesticated, which implies that the direct ancestor of the dog is extinct. The dog was the first species to be domesticated and has been selectively bred over millennia for various behaviors, sensory capabilities, and physical attributes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      